
.v-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.v-carousel-items {
  overflow: hidden;
}
.v-carousel-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}
.v-carousel-dot {
  cursor: pointer;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
}
.v-carousel-dot.active {
  background: #000;
  cursor: default;
}
.v-carousel-nav {
  cursor: pointer;
  position: absolute;
  line-height: 30px;
  color: #FFF;
  padding: 0 5px;
  background: rgba(0, 0, 0, 0.5);
  top: 50%;
  margin-top: -15px;
}
.v-carousel-nav.prev {
  left: 0;
}
.v-carousel-nav.next {
  right: 0;
}


.v-carousel-item {
  width: 100%;
  float: left;
}

